import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Cart from "./Cart";

const NewNav = ({ props }) => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [clothingMobileSubMenuOpen, setClothingMobileSubMenuOpen] =
    React.useState(false);
  const [miscMobileSubMenuOpen, setMiscMobileSubMenuOpen] =
    React.useState(false);

  useEffect(() => {
    setClothingMobileSubMenuOpen(false);
    setMiscMobileSubMenuOpen(false);
  }, [toggleMenu]);

  return (
    <div>
      <header className="navbar-container">
        <div className="navbar-desktop">
          <div className="navbar-social-links-container">
            <ul className="navbar-social-links">
              <li>
                <a
                  href="https://www.youtube.com/user/JeromeASF/featured"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-youtube fa-1x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/JeromeASF"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-twitter fa-1x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/JeromeAceti/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-instagram fa-1x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/JeromeASF"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-facebook fa-1x"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="navbar-logo-container">
            <NavLink to={"/"}>
              <img
                className="navbar-logo"
                src="/././Assets/logo.png"
                alt="navbar logo"
                width="132px"
                height="65px"
              />
            </NavLink>
          </div>

          <ul className="navbar-buttons">
            <NavLink
              to={"/latest"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Latest Release
            </NavLink>
            <NavLink to={"/shirts"} className="navbar-buttons-text">
              Shirts
            </NavLink>
            <NavLink
              to={"/hoodies"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Hoodies
            </NavLink>
            <NavLink
              to={"/accessories"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Accessories
            </NavLink>
            <li
              style={{
                listStyle: "none",
              }}
            >
              <Cart />
            </li>
          </ul>

          <div className="navbar-mobileIcon-container">
            <MenuIcon
              className="navbar-mobile-dropdown"
              fontSize="large"
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          </div>

          {/*--------------------- MOBILE MENU ---------------------*/}

          {toggleMenu && (
            <div className="navbar-mobile-dropdown-container">
              <ul className="navbar-mobile-dropdown-list">
                <NavLink to={"/latest"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Latest Release
                  </li>
                </NavLink>

                <NavLink to={"/shirts"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Shirts
                  </li>
                </NavLink>
                <NavLink to={"/hoodies"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Hoodies
                  </li>
                </NavLink>
                <NavLink to={"/accessories"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Accessories
                  </li>
                </NavLink>
              </ul>
            </div>
          )}
        </div>

        {/* Mobile */}
        <div className="navbar-mobile"></div>
      </header>
      {/* <div className="App__announcement">
        <h1 className="App__announcement-text">
          After December 2nd we cannot guarantee delivery by Christmas
        </h1>
        <Countdown
      date={1732863599000}
      renderer={Renderer}
    />
      </div> */}
    </div>
  );
};

export default NewNav;
